import React, { useState, useEffect } from 'react'
import Navigation from 'components/Navigation'
import logo from 'assets/icons/logo.png'
import menu from 'assets/icons/menu.svg'

function Header() {
  const [showNavigation, setShowNavigation] = useState(false)
  const [isHeaderVisible, setIsHeaderVisible] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      if (Math.abs(currentScrollY - lastScrollY) > 200) {
        setIsHeaderVisible(currentScrollY < lastScrollY)
        setLastScrollY(currentScrollY)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [lastScrollY])

  const handleClick = () => {
    setShowNavigation(!showNavigation)
  }

  return (
    <>
      <header
        className={`fixed top-0 z-30 flex w-full flex-col items-center justify-center shadow-xl transition-all duration-1000 ${isHeaderVisible ? 'translate-y-0' : '-translate-y-full'}`}
      >
        <nav className='flex w-full items-center justify-between bg-white p-3 md:px-20'>
          <a href='https://pitangapodemais.com.br'>
            <img src={logo} alt='' className='w-64' />
          </a>

          <ul className='hidden items-center gap-10 md:flex'>
            <li className='text-xl'>
              <a href='#inicio' className='font-semibold text-blue duration-500 uppercase transition-all hover:text-pink'>
                Início
              </a>
            </li>
            {/* <li className='text-xl'>
              <a href='#quem-somos' className='font-semibold text-blue duration-500 uppercase transition-all hover:text-pink'>
                Quem Somos
              </a>
            </li> */}
            <li className='text-xl'>
              <a
                href='#nossos-candidatos'
                className='font-semibold text-blue duration-500 uppercase transition-all hover:text-pink'
              >
                Nossos Candidatos
              </a>
            </li>
            <li className='text-xl'>
              <a href='#plano-de-governo' className='font-semibold text-blue duration-500 uppercase transition-all hover:text-pink'>
                Plano de Governo
              </a>
            </li>
            <li className='text-xl'>
              <a href='#contato' className='font-semibold text-blue duration-500 uppercase transition-all hover:text-pink'>
                Contato
              </a>
            </li>
          </ul>

          <div className='flex md:hidden' onClick={handleClick}>
            <img src={menu} alt='Menu' className='h-10 w-10' />
          </div>
        </nav>
      </header>

      <Navigation isOpen={showNavigation} onClose={handleClick} />
    </>
  )
}

export default Header
