import React from 'react'
import republicanos from 'assets/icons/republicanos.png'
import instagram from 'assets/icons/instagram.svg'
import facebook from 'assets/icons/facebook.svg'
import email from 'assets/icons/email.svg'

function Footer() {
  return (
    <footer id='contato' className='flex flex-col min-h-[50vh] w-full bg-blue text-white'>
      <div className='flex flex-col flex-1 justify-between items-center'>
        <div className='w-full bg-yellow p-4'></div>
        
        <div className='grid grid-cols-1 md:grid-cols-4 justify-center gap-8 w-11/12 md:w-10/12 py-8'>
          <a href='https://pitangapodemais.com.br'>
            <img src={republicanos} alt='União Brasil Logo' className='h-auto w-56 rounded-xl bg-white p-2' />
          </a>

          <div className='md:text-left'>
            <h3 className='mb-2 text-2xl font-bold text-white uppercase'>Links Importantes</h3>
            <ul className='flex flex-col gap-2'>
              <li>
                <a href='#inicio' className='text-blue-200 hover:text-pink transition-all duration-500'>
                  Início
                </a>
              </li>
              <li>
                <a href='#nossos-candidatos' className='text-blue-200 hover:text-pink transition-all duration-500'>
                  Nossos Candidatos
                </a>
              </li>
              <li>
                <a href='#plano-de-governo' className='text-blue-200 hover:text-pink transition-all duration-500'>
                  Plano de Governo
                </a>
              </li>
            </ul>
          </div>

          <div className='md:text-left'>
            <h3 className='mb-2 text-2xl font-bold text-white uppercase'>Contato</h3>
            <ul className='flex flex-col gap-2'>
              <li>
                <a href='#' target='_blank' className='text-blue-200 hover:text-pink transition-all duration-500'>
                  Pitanga - PR, 85200-000
                </a>
              </li>
              <li>
                <a href='mailto:pitangapodemais@gmail.com' className='text-blue-200 hover:text-pink transition-all duration-500'>
                  pitangapodemais@gmail.com
                </a>
              </li>
            </ul>
          </div>

          <div className='md:text-left'>
            <h3 className='mb-4 text-xl font-bold text-white uppercase'>Redes Sociais</h3>
            <ul className='flex flex-col gap-2'>
              <li>
                <a
                  href='https://instagram.com/pitangapodemais/'
                  target='_blank'
                  className='text-blue-200 flex items-center gap-2 hover:text-pink transition-all duration-500'
                >
                  <img src={instagram} alt='Instagram' className='h-6 w-6' />
                  <span>@pitangapodemais</span>
                </a>
              </li>
              <li>
                <a
                  href='https://facebook.com/pitangapodemais/'
                  target='_blank'
                  className='text-blue-200 flex items-center gap-2 hover:text-pink transition-all duration-500'
                >
                  <img src={facebook} alt='Facebook' className='h-6 w-6' />
                  <span>@pitangapodemais</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className='w-full bg-pink p-4 text-white text-blue-900 flex flex-col items-center justify-around gap-2 text-center text-xs md:flex-row'>
          <p className='flex-1'>
          ELEICAO 2024 ANTONIO FERNANDO TEIGAO PREFEITO: CNPJ 56.857.013/0001-36.
          </p>
          <p className='flex-1'>&copy;ELEICAO 2024 ANTONIO FERNANDO TEIGAO PREFEITO. Todos os direitos reservados.</p>
          <p className='flex-1'>
            <a href='https://dorac.com.br' target='_blank' className='text-blue-900 hover:underline transition-all duration-500'>
              Desenvolvido por Agência Dorac
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
