import React from 'react'

function GovernmentPlan() {
  const cards = [
    {
      title: 'Gestão Pública',
      icon: 'admin_panel_settings',
      topics: [
        'Criar um sistema de coordenação integrada entre as secretarias, assegurando ações de forma coordenada, modernizando a eficiência de controle de projetos e documentos, dando mais transparência e agilidade a administração.',
        'Readequar a estrutura organizacional do Município para melhor implantação das políticas públicas em cada área de atuação dos órgãos, mais eficiência nos serviços prestados e favorecendo a captação de recursos.',
        'Promover reforma administrativa com reestruturação de cargos, capacitação e valorização dos servidores.',
        'Fortalecer os Conselhos Municipais e adequar prédios públicos para acessibilidade.',
        'Melhorar o Portal de Transparência e reduzir despesas desnecessárias.',
        'Estabelecer Parcerias Público-Privadas em diversas áreas.',
        'Estudar e propor soluções para a situação do RPPS.',
      ],
    },
    {
      title: 'Desenvolvimento Econômico',
      icon: 'trending_up',
      topics: [
        'Reestruturar os órgãos municipais, visando o desenvolvimento econômico e sustentável através da inovação, incrementando os setores tradicionais, atraindo novos investimentos, adensando cadeias produtivas, atuando em conjunto com as demais áreas de governo na implementação de políticas públicas de desenvolvimento do município.',
        'Promover o desenvolvimento turístico na área urbana e rural do município, explorando principalmente as riquezas naturais, patrimônio cultural, histórico e artístico, turismo de negócios, gastronomia, saúde, religioso e de aventura, com melhorias na infraestrutura e acesso dos locais turísticos e material de divulgação.',
        'Promover a criação do Programa Primeiro Emprego, visando oferecer aos jovens o primeiro passo para o mercado de trabalho por meio de empregos iniciais, estágios e capacitação profissional, integrando as necessidades das empresas locais com o potencial da juventude do município.',
        'Apoiar, fomentar e buscar parcerias para o desenvolvimento de atividades de pesquisa tecnológica, estimular startups, criar espaço para instalação de incubadora tecnológica objetivando atrair empresas ligadas à área e inserir o conceito de cidades inteligentes (smart cities).',
        'Formalizar e dar suporte a microempreendedores individuais (MEIs), visando melhorar a capacitação, fortalecimento, planejamento e execução de metas para a expansão dos negócios (Agência do Trabalhador de Pitanga e seus respectivos setores).',
        'Proporcionar o desenvolvimento econômico e sustentável, viabilizando projetos de incentivo à indústria, com a concessão de terrenos e barracões, projetos industriais e análise de incentivos físicos, tributários e financeiros.',
        'Apoiar e incentivar o comportamento empreendedor e educação financeira, oferecendo cursos, treinamentos, workshops, palestras e outras fontes de informação de modo gratuito à população (SENAI, SENAC, SEBRAE, FIEP).',
        'Apoiar empreendedores locais para participar de eventos de turismo a nível estadual e nacional, adquirindo conhecimento e promovendo as experiências turísticas locais.',
        'Buscar recursos para a construção de Parque de Exposições permanente, para realização de Feiras Agropecuárias e Comerciais e atividades de lazer, como a Festcentro.',
        'Reestruturação da logística de produtos ligados à agricultura familiar e artesanato para promover e estimular o aumento da renda familiar no município.',
        'Promover a criação de incubadora de empresas, com serviço de apoio e suporte nas primeiras etapas de vida para desenvolver seus negócios.',
        'Motivar o uso do Paraná Negócios - portal CELEPAR.',
        'Fomentar o agronegócio.',
      ],
    },
    {
      title: 'Saúde',
      icon: 'local_hospital',
      topics: [
        'Disponibilizar um número maior de exames laboratoriais e de imagens priorizando os serviços prestados no Município; promovendo agilidade de retorno ao profissional médico, levando a um diagnóstico mais rápido e preciso e tratamento mais precoce.',
        'Aumentar o acesso de usuários ao Centro de Atenção Psicossocial viabilizando o Programa de Nível II, tendo como consequência ampliação dos cuidados disponibilizados pela equipe multiprofissional na saúde mental e para dependentes químicos.',
        'Fortalecer as Unidades Básicas de Saúde, ampliando o número de profissionais nas áreas de fisioterapia, nutrição, terapia ocupacional e psicologia de forma a atender integralmente os usuários do sistema.',
        'Elencar um maior número de medicamentos na Farmácia Municipal para viabilizar as receitas médicas na sua totalidade; sistematizar o serviço para controle rigoroso da oferta aos usuários.',
        'Avaliar as condições dos transportes dos usuários do Sistema de Saúde, melhorando as condições de deslocamento, assegurando conforto e segurança a todos que necessitem.',
        'Concluir e estruturar o Hospital Regional fortalecendo a média complexidade hospitalar com profissionais especializados, centralizando o atendimento a saúde em Pitanga.',
        'Viabilizar um estudo para implementação de novas equipes de Estratégia Saúde da Família levando às comunidades rurais profissionais e programas de forma sistematizada.',
        'Apoiar iniciativas privadas que visam atender pacientes em tratamentos oncológicos nas suas necessidades emocionais e físicas promovendo cuidado integral.',
        'Melhorar e ampliar o Programa Municipal de Odontologia, nas diferentes faixas etárias, assegurando aos usuários acesso aos tratamentos de forma integral.',
        'Implantar o Pronto Atendimento no Centro Municipal de Saúde em horário estendido facilitando o acesso dos usuários ao serviço multiprofissional.',
        'Promover estratégias no Programa Municipal de Imunização para que a população, em todas faixas etárias, se mantenha imune à doenças preveníveis.',
        'Fortalecer o Consórcio Intermunicipal de Saúde mantendo Pitanga como polo regional de especialidades médicas.',
      ],
    },
    {
      title: 'Educação e Cultura',
      icon: 'school',
      topics: [
        'Buscar recursos para melhoria nas instituições de ensino da rede pública municipal, como construções de escolas, salas de aula, laboratório de informática, banheiros, refeitórios, quadra poliesportivas, para que a Educação Integral/Jornada Ampliada seja oferecida com qualidade.',
        'Promover exposições artísticas, festivais de música e outras atividades culturais, na área urbana e rural, com a valorização dos talentos locais.',
        'Ter uma equipe de profissionais (fonoaudiólogos, psicólogos, psicopedagogos) com carga horária suficiente para cobrir as demandas das escolas.',
        'Oferecer horário diferenciado para os CEMEIS (Centro de Educação Infantil) e buscar alternativas para eliminar filas de espera.',
        'Valorização dos profissionais da educação e oferecimento de concurso público para diminuir a rotatividade dos professores.',
        'Ofertar formação continuada para todos os profissionais da educação com temas relevantes a sua prática diária.',
        'Incentivar e promover cursos para apoiar novos talentos nos mais diversos segmentos e expressões culturais.',
        'Atualização de materiais pedagógicos, bem como, adquirir obras literárias para acervos nas escolas.',
        'Ampliar o programa da merenda escolar, com mais qualidade e variedade.',
        'Apoiar o Museu Municipal, promovendo sua divulgação e utilização.',
        'Garantir a qualidade do transporte escolar.',
        'Abastecimento de água e de esgoto.',
      ],
    },
    {
      title: 'Desenvolvimento Urbano',
      icon: 'location_city',
      topics: [
        'Revisar e implantar o Plano de Mobilidade Urbana, permitindo que as pessoas se desloquem na cidade para desenvolver relações sociais e econômicas.',
        'Revisar a legislação urbana, visando a adequação à realidade local, agilidade e eficiência em sua aplicação.',
        'Promover gestões junto aos órgãos competentes para ampliação da rede de coleta e destinação de esgoto.',
        'Desenvolver ações que evitem enchentes e alagamentos na área urbana.',
        'Revitalizar a área central da cidade e promover a acessibilidade.',
        'Ampliar a execução de pavimentação asfáltica nas vias urbanas.',
        'Ampliar o serviço de transporte coletivo urbano.',
        'Reformar e construir praças e áreas de lazer.',
        'Desenvolver programa de arborização urbana.',
      ],
    },
    {
      title: 'Desenvolvimento Rural',
      icon: 'agriculture',
      topics: [
        'Desenvolver projeto de apicultura, com distribuição de kits completos aos apicultores interessados e, posteriormente, implantação de uma agroindústria para processamento do mel.',
        'Fomentar o desenvolvimento da produção de erva mate local e implantação de novas áreas de maneira técnica para otimizar a produção e melhorar o manejo.',
        'Implantar projeto de manejo de pastagem com a mecanização, fornecimento de sementes adequadas para a região e acompanhamento técnico.',
        'Promover a construção de tanques de peixes para pequenos produtores com distribuição ou fornecimento de alevinos com parceiros.',
        'Implantar projeto de horticultura e fruticultura, com começo, meio e fim, visando o manejo completo e acompanhamento.',
        'Desenvolver projeto de perfuração e instalação de poços artesianos em comunidades rurais em pontos estratégicos.',
        'Promover eficiência nos serviços de construção, recuperação e manutenção das estradas rurais, pontes e bueiros.',
        'Implantação de projeto para distribuição de calcário e adubo, visando atender os pequenos produtores.',
        'Fomentar a bovinocultura de leite, fornecimento de sêmen e outras ações necessárias.',
        'Buscar parcerias com instituições estaduais, federais e municipais.',
        'Buscar e incentivar a implantação de aviário de frangos.',
        'Desenvolver projeto para produção de ovo caipira.',
        'Fortalecer os projetos já em andamento.',
      ],
    },
    {
      title: 'Esporte',
      icon: 'sports',
      topics: [
        'Apoiar atividades de bolão e bocha, xadrez, tênis de mesa, ciclismo, ginástica artística, atletismo, karatê, judô e outras modalidades de artes marciais, caminhada na natureza, competições de laço comprido, a tradicional tropeada e outras modalidades esportivas e de lazer. ',
        'Criação das escolinhas de base em todas as modalidades esportivas, em parceria com a Secretaria de Educação, incentivando a frequência escolar',
        'Incentivar e promover campeonatos na cidade e nas comunidades rurais nas mais diversas modalidades esportivas.',
        'Buscar recursos para melhorias nas instituições de ensino e garantir qualidade do transporte escolar.',
        'Ampliar a oferta de estrutura física, na área urbana e rural, para atividades de lazer e esportes.',
        'Criação do projeto interbairros e municipal nas categorias Sub 7, Sub 10 e Sub 15.',
        'Criação de campeonato para veteranos, em diversas modalidades esportivas.',
        'Implantar Campeonato Municipal de Futebol Feminino.',
        'Reformulação e apoio à Ligada Amadora de Futebol.',
      ],
    },
    {
      title: 'Meio Ambiente',
      icon: 'eco',
      topics: [
        'Reestruturar o órgão municipal de Meio Ambiente, favorecendo a captação de recursos,  promovendo o avanço na gestão ambiental e visando um município sustentável e  preparado para as mudanças ambientais futuras.',
        'Promover melhorias na infraestrutura do aterro sanitário e viabilizar a construção de um aterro sanitário em células.',
        'Promover gestões junto à Sanepar, visando a melhoria e ampliação das redes de Saneamento básico.',
        'Construção de uma composteira para o município e nas comunidades.',
        'Incentivo ao turismo e criação de Unidades de Conservação. ',
        'Aumento de pontos de entrega de resíduos especiais (PEVs).',
        'Estruturação da coleta seletiva municipal.',
      ],
    },
    {
      title: 'Segurança Pública',
      icon: 'security',
      topics: [
        'Estabelecer cooperação mais estreita com as forças estaduais (Policia Militar e Civil), facilitando a troca de informações e o planejamento de operações conjunta, visando a redução da criminalidade na área urbana e na área rural do município.',
        'Apoiar o Conselho Comunitário de Segurança, promovendo o diálogo entre moradores e autoridades, tendo uma escuta ativa com a comunidade, para planejar e executar ações voltadas a soluções de situações de riscos.',
        'Ampliar o sistema de câmeras de vigilância em pontos estratégicos da cidade, como entradas e saídas do município, áreas comerciais e locais de maior movimentação.',
        'Modernizar a iluminação pública em toda a cidade, iniciando em áreas com maior incidência de crimes e as áreas monitoradas por câmeras.',
        'Fortalecer a Guarda Municipal.',
      ],
    },
    {
      title: 'Assistência Social e Cidadania',
      icon: 'people',
      topics: [
        'Promover gestão junto aos órgãos estaduais e federais visando a implantação de conjuntos habitacionais, para atender a demanda de moradia popular.',
        'Disponibilizar um veículo móvel para o cadastro único realizar busca ativa em comunidades da área rural.',
        'Reestruturar os serviços de fortalecimento de vínculos da pessoa idosa, criança e adolescente.',
        'Ampliar a oferta dos serviços, Programas e Projetos e benefícios socioassistenciais.',
        'Garantir equipes mínimas de RH, NOB, SUAS, de acordo com suas necessidades.',
        'Realizar diagnóstico socio-territorial da política da assistência.',
        'Fortalecer os trabalhos de regularização fundiária.',
      ],
    },
  ]

  return (
    <section id='plano-de-governo' className='flex w-full flex-col items-center justify-center gap-4 bg-gray-50'>
      <div className='my-8 flex w-11/12 flex-col items-center justify-center gap-4 md:w-10/12'>
        <header className='mb-4 flex flex-col items-center justify-center gap-2 text-center'>
          <h2 className='text-2xl font-bold uppercase md:text-5xl'>Plano de Governo</h2>
          <p className='text-lg w-full md:w-9/12'>
            Pitanga tem um potencial incrível e acreditamos que ela pode+! E Pensando nisso, elaboramos um plano de governo que reflete nossos sonhos e objetivos. Vamos juntos construir um amanhã melhor para todos!
          </p>
        </header>

        <div className='grid grid-cols-1 gap-6'>
          {cards.map((card, index) => (
            <div
              key={index}
              className='flex flex-col gap-4 rounded-xl bg-green p-6 text-white shadow-md transition-all duration-300'
            >
              <header className='flex items-center gap-2'>
                <span className='material-icons text-4xl' aria-hidden='true'>
                  {card.icon}
                </span>
                <h3 className='text-2xl font-semibold md:text-3xl uppercase'>{card.title}</h3>
              </header>

              <ul className='grid list-disc grid-cols-1 gap-2 ps-4 md:grid-cols-2'>
                {card.topics.map((topic, topicIndex) => (
                  <li key={topicIndex} className='text-justify'>
                    <p className='w-full text-justify md:w-11/12'>{topic}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default GovernmentPlan
