import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// global components
import Header from 'components/Header'
import Footer from 'components/Footer'

// local components
import Banner from './components/Banner.jsx'
import OurCandidates from './components/OurCandidates.jsx'
import About from './components/About'
import GovernmentPlan from './components/GovernmentPlan.jsx'

function Home() {
  const location = useLocation();
  const targetElement = useRef(null);

  useEffect(() => {
    const hash = location.hash;
    if (hash && hash.startsWith('#')) {
      const elementId = hash.slice(1);
      targetElement.current = document.getElementById(elementId);
      if (targetElement.current) {
        targetElement.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    document.title = 'Pitanga Pode Mais';
    window.scrollTo(0, 0);
  });

  return (
    <main className='overflow-hidden'>
      <Header />
      <Banner />
      {/* <About /> */}
      <OurCandidates />
      <GovernmentPlan />
      <Footer />
    </main>
  );
}

export default Home;