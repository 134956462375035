import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import alfredo from 'assets/images/santinhos/alfredo.png'
import camila from 'assets/images/santinhos/camila.png'
import carlao from 'assets/images/santinhos/carlao.png'
import celso from 'assets/images/santinhos/celso.png'
import fabiano from 'assets/images/santinhos/fabiano.png'
import fininho from 'assets/images/santinhos/fininho.png'
import gaguinho from 'assets/images/santinhos/gaguinho.png'
import marcos from 'assets/images/santinhos/marcos.png'
import maria from 'assets/images/santinhos/maria.png'
import marlene from 'assets/images/santinhos/marlene.png'
import mauro from 'assets/images/santinhos/mauro.png'
import nene from 'assets/images/santinhos/nene.png'
import sonia from 'assets/images/santinhos/sonia.png'

const OurCandidates = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: null,
    prevArrow: null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          autoplaySpeed: 4000,
          dots: true,
          nextArrow: null,
          prevArrow: null,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplaySpeed: 3000,
          dots: false,
          nextArrow: null,
          prevArrow: null,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplaySpeed: 3000,
          dots: false,
          nextArrow: null,
          prevArrow: null,
        },
      },
    ],
  }

  const candidates = [
    { name: 'Alfredo', image: alfredo },
    { name: 'Camila', image: camila },
    { name: 'Carlão', image: carlao },
    { name: 'Celso', image: celso },
    { name: 'Fabiano', image: fabiano },
    { name: 'Fininho', image: fininho },
    { name: 'Gaguinho', image: gaguinho },
    { name: 'Marcos', image: marcos },
    { name: 'Maria', image: maria },
    { name: 'Marlene', image: marlene },
    { name: 'Mauro', image: mauro },
    { name: 'Nenê', image: nene },
    { name: 'Sônia', image: sonia },
  ]

  return (
    <section id='nossos-candidatos' className='flex w-full flex-col items-center justify-center'>
      <div className='my-8 w-11/12 text-center md:w-10/12 md:py-8'>
        <header className='mb-4 flex flex-col items-center justify-center gap-2'>
          <h2 className='text-2xl font-bold uppercase md:text-5xl'>Nossos Vereadores</h2>
          <p className='w-full text-lg md:w-9/12'>
            Conheça quem acredita que Pitanga pode+! Estes são os representantes da nossa comunidade, prontos para
            ouvir suas necessidades e trabalhar pelo que é melhor para nossa cidade.
          </p>
        </header>

        <Slider {...settings}>
          {candidates.map((candidate) => (
            <div className='p-2' key={candidate.name}>
              <img
                src={candidate.image}
                alt={candidate.name}
                loading='lazy'
                className='rounded-xl'
                onError={() => console.error(`Erro ao carregar imagem: ${candidate.image}`)}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default OurCandidates
